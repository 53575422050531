<template>
    <div>
        <div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
            <export_excel :json_data="json_data" :json_fields="json_fields" name="微博.xls" />
            <media_screen path_arr="path_array1" :category_id="5" @screen_confirm="pageChange(1)"
                @reset_screen="pageChange(1)" />

            <Title title="资源列表" />
            <div class="table_box">
                <table>
                    <thead>
                        <td>媒介名称</td>
                        <td>直发价格</td>
                        <td>转发价格</td>
                        <td>行业类型</td>
                        <td>所属区域</td>
                        <td>参考粉丝数</td>
                        <!-- <td>截稿时间</td>
                            <td>出稿速度</td> -->
                        <td>可发内链</td>
                        <td>可发联系方式</td>
                        <td>V认证</td>
                        <td>备注(仅供参考)</td>
                        <td>操作</td>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td>
                                <td_remark :text="item.title" />
                            </td>
                            <td>
                                <button :class="item.check == 'direct_price' ? 'priceActive' : 'price'"
                                    @click.stop="onPitch(index, 'direct_price')">￥{{
            item.direct_price.toFixed(2)
        }}
                                </button>
                            </td>
                            <td>
                                <button :class="item.check == 'member_forward_price' ? 'priceActive' : 'price'"
                                    @click.stop="onPitch(index, 'member_forward_price')">￥{{
            item.member_forward_price.toFixed(2)
        }}
                                </button>
                            </td>
                            <td>{{ item.industry_classify_title }}</td>
                            <td>{{ item.area_title }}</td>
                            <td>{{ item.fans_num }}</td>
                            <!-- <td>{{ item.stop_draft_time == 0 ? '无' : item.stop_draft_time + ':00' }}</td>
                                <td>{{
            item.draft_speed == 0
                ? '不限'
                : item.draft_speed == 1
                    ? '1小时'
                    : item.draft_speed == 2
                        ? '2小时'
                        : item.draft_speed == 3
                            ? '3小时'
                            : item.draft_speed == 4
                                ? '4小时'
                                : item.draft_speed == 5
                                    ? '当天'
                                    : '次日'
        }}</td> -->
                            <td :style="{ color: item.is_pub_link == 1 ? '#55C964' : '#FF0000' }">
                                {{ item.is_pub_link == 0 ? '否' : '是' }}
                            </td>
                            <td :style="{ color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000' }">
                                {{ item.is_pub_contact == 0 ? '否' : '是' }}
                            </td>
                            <td>
                                {{ item.is_v_auth == 0 ? '否' : item.is_v_auth == 1 ? '是' : item.is_v_auth == 2 ?
            '蓝V认证'
            :
            item.is_v_auth == 3 ?
                '黄V认证' : '红V认证' }}
                            </td>
                            <td>
                                <td_remark :text="item.remark" />
                            </td>
                            <td>
                                <div class="collect_box flex">
                                    <p @click.stop="jumpLink(item)">查看案例</p>
                                    <!-- v-if="item.collect == 0"  -->
                                    <p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏
                                    </p>
                                    <!-- v-if="item.collect == 1" -->
                                    <p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- 分页器 -->
                <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
            </div>
        </div>
        <!-- 购物车 -->
        <div v-show="shopShow == false" class="shopping_car">
            <div class="car" @click.stop="shopShow = true">
                <p>{{ CarList.length }}</p>
            </div>
        </div>
        <div :class="shopShow ? 'shop_box' : 'shop_none_box'">
            <div class="title flex" style="justify-content: space-between; align-items: center">
                <h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
                <button class="empty_but" v-show="CarList.length != 0" @click="empty()">清空已选媒体</button>
                <span class="close" @click="shopShow = false"></span>
            </div>
            <ul class="listbox" v-show="CarList.length != 0">
                <li v-for="(item, index) in CarList" :key="index">
                    <!-- <img :src="item.logo" alt="" /> -->
                    <div>
                        <td_remark :text="item.title" />
                    </div>
                    <p class="price">{{ item.check == 'direct_price' ? `直发价格:￥${item.direct_price.toFixed(2)}` :
                        `转发价格:￥${item.member_forward_price.toFixed(2)}` }}</p>
                    <span class="deleteIcon" @click.stop="detale(item)"></span>
                </li>
            </ul>
            <el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
            <div class="bottom_box">
                <div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
                    <p style="font-size: 1.4rem; color: #999">合计：</p>
                    <p class="totalMoney">
                        ￥<span>{{ totalMoney }}</span>
                    </p>
                </div>
                <button class="put_but" @click="putFn(item)">立即投放</button>
            </div>
        </div>
    </div>
</template>

<script>
import media_screen from '@/components/media_screen'
import { media_screen_ } from '@/util/media_screen_'
import { mapState } from 'vuex'
import export_excel from '@/components/export_excel';
export default {
    components: {
        media_screen,
        export_excel
    },
    computed: {
        ...mapState(['media_screen_data', 'fillActive']),
        totalMoney: function () {
            var n = 0;
            for (var i = 0; i < this.CarList.length; i++) {
                if (this.CarList[i].check == 'direct_price') {
                    n += this.CarList[i].direct_price;
                } else if (this.CarList[i].check == 'member_forward_price') {
                    n += this.CarList[i].member_forward_price;
                }
            }
            return n.toFixed(2);
        }
    },

    watch: {
        CarList: {
            handler() {
                this.excelPush()
            },
            deep: true,
        }
    },
    data() {
        return {
            /*数据表格*/
            tableData: [],
            CarList: [],
            // 购物车任务栏切换
            shopShow: false,
            Allcheck: false,
            AllcheckNum: 0, //选中总数
            ids: [],
            fullscreenLoading: false,
            count: 0, //总条数
            total_page: 0, //分页总页数
            page: 1,
            json_fields: {
                " 媒介名称": 'title',
                "直发价格": 'direct_price',
                "转发价格": 'member_forward_price',
                "频道类型": 'industry_classify_title',
                "案例链接": 'case_url',
                "所属区域": 'area_title',
                "可发内链": 'is_pub_url',
                "可发联系方式": 'is_pub_contact',
                "收录类型": 'inlet_category',
                "出稿速度": 'draft_speed',
                "V认证": 'is_v_auth',
                "备注": 'remark',
            },
            json_data: []
        }
    },
    methods: {
        pageChange(pageVal) {
            this.page = pageVal
            this.get_list()
        },
        onPitch(index, type) {
            this.shopShow = true
            console.log('tableDate:', this.tableData[index].check, type);
            // this.tableData[index].check = type
            const result = this.CarList.every(item => item.id !== this.tableData[index].id);
            if (result == true) {
                this.tableData[index].check = type
                this.CarList.push(this.tableData[index])
            } else {
                this.CarList.forEach((item, i) => {
                    if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
                        console.log(this.CarList[i].check);
                        this.tableData[index].check = type
                        this.CarList[i].check = type
                    } else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
                        console.log(2);
                        this.CarList.splice(i, 1)
                        this.tableData[index].check = ''
                    }
                })
            }
        },
        detale(element) {
            this.CarList.forEach((el, i) => {
                if (el.id == element.id) {
                    this.CarList.splice(i, 1)
                }
            })
            // this.CarList.splice(index,1)
            this.tableData.forEach((item, index) => {
                if (item.id == element.id) {
                    this.tableData[index].check = ''
                }
            })
        },
        // 清空购物车
        empty() {
            this.$confirm('是否清空已选媒体?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.fullscreenLoading = true
                    // setTimeout(() => {
                    this.CarList = []
                    this.shopShow = false
                    // this.toggleSelection()
                    this.tableData.forEach((item, index) => {
                        if (item.check) {
                            console.log(item)
                            return (item.check = false)
                        }
                    })
                    this.fullscreenLoading = false
                    // }, 500)
                    this.$message({
                        type: 'success',
                        message: '已清空已选媒体',
                        offset: 80
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'warning',
                        message: '已删除',
                        offset: 80

                    })
                })
        },
        createBall(left, top) {
            const bar = document.createElement('ball')
            bar.style.position = 'fixed'
            bar.style.left = left + 'px'
            bar.style.top = top + 'px'
            bar.style.width = '20px'
            bar.style.height = '20px'
            bar.style.borderRadius = '50%'
            bar.style.backgroundColor = 'red'
            bar.style.transition =
                'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
            document.body.appendChild(bar)
            setTimeout(() => {
                const x = document.body.clientWidth * 1.5
                const y = document.body.clientHeight * 1 - 500
                bar.style.top = y + 'px'
                console.log(y)
                bar.style.left = x + 'px'
            }, 0)
            bar.ontransitionend = function () {
                this.remove()
            }
        },
        // 获取列表数据
        get_list() {
            let data = media_screen_(this.$store.state.media_screen_data.date_form)
            data.page = this.page
            data.limit = 20
            this.fullscreenLoading = true
            this.curlGet('/api/medium/list', data).then(res => {
                if (res.data.code) {
                    this.tableData = res.data.data.list
                    this.count = res.data.data.count
                    this.total_page = res.data.data.total_page
                    this.tableData.forEach((item, index) => {
                        this.$set(this.tableData[index], 'check', false)
                    })
                    this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
                }
                this.fullscreenLoading = false
            })
        },
        excelPush() {
            this.json_data = []
            this.CarList.forEach((item, index) => {
                this.json_data.push({
                    title: item.title,
                    direct_price: item.direct_price,
                    member_forward_price: item.member_forward_price,
                    industry_classify_title: item.industry_classify_title,
                    case_url: item.case_url,
                    area_title: item.area_title,
                    is_pub_url: item.is_pub_url ? '是' : '否',
                    is_pub_contact: item.is_pub_contact ? '是' : '否',
                    inlet_category: item.inlet_category == 0 ? '不限' : item.inlet_category == 1 ? '包网页收录' : item.inlet_category == 2 ? '网页收录' : item.inlet_category == 3 ? '包百度新闻源收录' : '百度新闻源收录',
                    draft_speed: item.draft_speed == 0 ? '不限' : item.draft_speed == 1 ? '1小时' : item.draft_speed == 2 ? '2小时' : item.draft_speed == 3 ? '3小时' : item.draft_speed == 4 ? '4小时' : item.draft_speed == 5 ? '当天' : '次日',
                    is_v_auth: item.is_v_auth ? '是' : '否',
                    remark: item.remark
                })
            })
        },
        // 立即投放
        putFn() {
            if (this.CarList.length == 0) {
                return this.$message({
                    type: 'warning',
                    message: '请选择媒介',
                })
            } else if (!this.$user_info) {
                this.$message({
                    type: 'warning',
                    message: '请登录',
                })
            } else {
                this.fullscreenLoading = true
                this.CarList.forEach((item, index) => {
                    this.CarList[index].price = this.CarList[index][this.CarList[index].check]
                    console.log(this.CarList[index]);
                })
                setTimeout(() => {
                    this.fullscreenLoading = false
                    this.$store.commit('shopPush', this.CarList)
                    if (this.$store.state.fillActive == false) {

                        this.$router.push('/user/SetMenu/ManuscriptPreview')
                    } else {
                        this.$router.push({
                            path: '/user/SetMenu/fill?id=5',
                        })
                    }
                }, 500)
            }
        },
        // 收藏
        collectGet(item) {
            console.log(item);
            this.curlGet('/api/medium/collect', {
                mid: item.id,
                style: 1
            }).then(res => {
                console.log(res)
                if (res.data.code) {
                    this.$message({
                        message: '收藏成功',
                        type: 'success'
                    })
                    this.get_list()
                }
            })
        },
        // 取消收藏
        collectClose(item) {
            this.$confirm('是否取消收藏?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.curlGet('/api/medium/collect', {
                    mid: item.id,
                    style: 1
                }).then(res => {
                    console.log(res)
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: '取消收藏成功!'
                        });
                        this.get_list()
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/shopCar';
@import '@/scss/overText';
@import '@/scss/user_media';
@import '@/scss/price';
</style>